import $ from 'jquery';
window.jQuery = $;
require('bootstrap');
var _ = require('lodash');

import slick from 'slick-carousel';
import 'magnific-popup';
import AOS from 'aos';

$(document).ready(function() {

  // Scroll trigger.
  $(window).on('scroll', function() {
    if ($(window).scrollTop() > 0) {
      $('#navigation').addClass('shadow');
    } else {
      $('#navigation').removeClass('shadow');
    }
  });

  var sticky = $('#navigation');
  var stickyHeight = sticky.outerHeight();
  var currentScrollPosition = $(window).scrollTop();
  var mobile = $(window).width() < 768;

  $('body').css('margin-top', stickyHeight + 'px');
  sticky.addClass('fixed');

  $(window).on('load resize', function() {
    mobile = $(window).width() < 768;
    stickyHeight = sticky.outerHeight();
    $('body').css('margin-top', stickyHeight + 'px');
    currentScrollPosition = $(window).scrollTop();
    scrollHandler();
  });

  $(window).on('scroll', _.throttle(scrollHandler, 100));

  function scrollHandler() {
    var newScrollPosition = $(window).scrollTop();
    var pastThePointOfNoReturn = newScrollPosition > stickyHeight;
    sticky.toggleClass('scrolled', pastThePointOfNoReturn && !mobile);
    var scrolledUp = newScrollPosition <= currentScrollPosition;
    sticky.toggleClass('show', scrolledUp && pastThePointOfNoReturn && !mobile);
    currentScrollPosition = newScrollPosition;
  };

  // Triggering burger menu.
  $('.burger-menu').click(function() {
    $('.burger-menu').toggleClass('burger-active');
    $('#mobile-navbar').toggleClass('navbar-active');
  });

  // Adds the active class to the relevant links.
  $('.navbar-nav [href]').each(function() {
    if (this.href == window.location.href) {
      $(this).addClass('active');
    }
  });

  // Development carousel.
  $('.development-carousel').slick({
    arrows: false,
    infinite: true,
    slidesToScroll: 1,
    slidesToShow: 3,
    responsive: [{
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
        }
      }
    ]
  });
  $('.slick-development.slick-prev').click(function() {
    $('.development-carousel').slick('slickPrev');
  });
  $('.slick-development.slick-next').click(function() {
    $('.development-carousel').slick('slickNext');
  });

  // Search form.
  $('.search-input').on('change', function() {
    if (this.value != 0) {
      $('.search-submit').prop('disabled', false);
      $('.search-form').attr('action', this.value);
    } else {
      $('.search-submit').prop('disabled', true);
      $('.search-form').attr('action', '');
    }
  });

  // Location trigger.
  $(document).on('click', '.location-trigger', function() {
    var clicked = $(this).data('ref');
    $('.developments-wrapper').each(function() {
      var current = $(this);
      if (current.data('ref') == clicked) {
        var item = $(this);
        $('.developments-wrapper').css('opacity', '0').css('display', 'none');
        $(item).css('display', 'block');
        setTimeout(function() {
          $(item).css('opacity', '1');
        }, 200);

        var windowWidth = $(window).width();
        if (windowWidth <= 767) {
          var offset = $('#navigation').outerHeight();
        } else {
          var offset = 0;
        }
        event.preventDefault();
        $('html, body').animate({
          scrollTop: item.offset().top - offset
        }, 1000);
      }
    });
  });

  // Smooth scroll.
  $('a[href*="#"]')
    .not('[href="#"]')
    .not('[href="#0"]')
    .not('.development-info-tab')
    .not('.transport-option-tab')
    .click(function(event) {
      if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
        var target = $(this.hash);
        var windowWidth = $(window).width();
        if (windowWidth <= 767) {
          var offset = $('#navigation').outerHeight();
        } else {
          var offset = 0;
        }
        target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
        if (target.length) {
          event.preventDefault();
          $('html, body').animate({
            scrollTop: target.offset().top - offset
          }, 1000, function() {
            var $target = $(target);
            $target.focus();
          });
        }
      }
    });

  
  // Partners carousel.
  $('.partners-carousel').slick({
    arrows: false,  
    dots: false, 
    infinite: false, 
    mobileFirst: true, 
    responsive: [
      {
        breakpoint: 1199, 
        settings: {
          slidesToShow: 4
        }
      }, 
      {
        breakpoint: 991, 
        settings: {
          slidesToShow: 3
        }
      }, 
      {
        breakpoint: 767, 
        settings: {
          slidesToShow: 2
        }
      }
    ], 
    slidesToShow: 1, 
    slidesToScroll: 1
  });
  $('.slick-partners.slick-prev').click(function() {
    $('.partners-carousel').slick('slickPrev');

    // let current = $('.active-slide');   
    // if(current.first()) {
    //   $(this).addClass('disable-button')
    // }

    // let next = $('.slick-next');
    // if($(next).hasClass('disable-button')) {
    //   $(next).removeClass('disable-button')
    // }
  });
  $('.slick-partners.slick-next').click(function() {
    $('.partners-carousel').slick('slickNext');

    // let current = $('.active-slide');
    // if(current.last()) {
    //   $(this).addClass('disable-button')
    // }

    // let prev = $('.slick-prev');
    // if($(prev).hasClass('disable-button')) {
    //   $(prev).removeClass('disable-button')
    // }
  });


  $('.dev-team-carousel').slick({
    arrows: false, 
    dots: false,
    infinite: false, 
    slidesToScroll: 1, 
    slidesToShow: 1
  });
  $('.slick-dev-team.slick-prev').click(function() {
    $('.dev-team-carousel').slick('slickPrev');

    // let current = $('.active-slide');   
    // if(current.first()) {
    //   $(this).addClass('disable-button')
    // }

    // let next = $('.slick-next');
    // if($(next).hasClass('disable-button')) {
    //   $(next).removeClass('disable-button')
    // }
  });
  $('.slick-dev-team.slick-next').click(function() {
    $('.dev-team-carousel').slick('slickNext');

    // let current = $('.active-slide');
    // if(current.last()) {
    //   $(this).addClass('disable-button')
    // }

    // let prev = $('.slick-prev');
    // if($(prev).hasClass('disable-button')) {
    //   $(prev).removeClass('disable-button')
    // }
  });


  $('.projects-carousel').slick({
    arrows: false, 
    dots: false,
    infinite: false, 
    slidesToScroll: 1, 
    slidesToShow: 1
  });
  $('.slick-projects.slick-prev').click(function() {
    $('.projects-carousel').slick('slickPrev');

    // let current = $('.active-slide');   
    // if(current.first()) {
    //   $(this).addClass('disable-button')
    // }

    // let next = $('.slick-next');
    // if($(next).hasClass('disable-button')) {
    //   $(next).removeClass('disable-button')
    // }
  });
  $('.slick-projects.slick-next').click(function() {
    $('.projects-carousel').slick('slickNext');

    // let current = $('.active-slide');
    // if(current.last()) {
    //   $(this).addClass('disable-button')
    // }

    // let prev = $('.slick-prev');
    // if($(prev).hasClass('disable-button')) {
    //   $(prev).removeClass('disable-button')
    // }
  });


  $('.partnership-carousel').slick({
    arrows: false, 
    dots: false,
    infinite: false, 
    slidesToScroll: 1, 
    slidesToShow: 1
  });
  $('.slick-partnerships.slick-prev').click(function() {
    $('.partnership-carousel').slick('slickPrev');

    // let current = $('.active-slide');   
    // if(current.first()) {
    //   $(this).addClass('disable-button')
    // }

    // let next = $('.slick-next');
    // if($(next).hasClass('disable-button')) {
    //   $(next).removeClass('disable-button')
    // }
  });
  $('.slick-partnerships.slick-next').click(function() {
    $('.partnership-carousel').slick('slickNext');

    // let current = $('.active-slide');
    // if(current.last()) {
    //   $(this).addClass('disable-button')
    // }

    // let prev = $('.slick-prev');
    // if($(prev).hasClass('disable-button')) {
    //   $(prev).removeClass('disable-button')
    // }
  });


  // Gallery carousel.
  $('.gallery-carousel').slick({
    arrows: false,
    centerMode: true,
    centerPadding: '25%',
    slidesToScroll: 1,
    slidesToShow: 1,
    responsive: [{
        breakpoint: 991,
        settings: {
          centerPadding: '80px',
        }
      },
      {
        breakpoint: 767,
        settings: {
          centerMode: false,
        }
      }
    ]
  });
  $('.slick-gallery.slick-prev').click(function() {
    $('.gallery-carousel').slick('slickPrev');
  });
  $('.slick-gallery.slick-next').click(function() {
    $('.gallery-carousel').slick('slickNext');
  });

  // Floor plan carousel.
  $('.floor-plan-carousel').slick({
    arrows: false,
    draggabe: false,
    fade: true,
    swipe: false,
  });
  $('.floor-plan-image-carousel').slick({
    arrows: false,
    draggabe: false,
    fade: true,
    swipe: false,
  });
  $('.slick-floorplan.slick-prev').click(function() {
    $('.floor-plan-carousel').slick('slickPrev');
    $('.floor-plan-image-carousel').slick('slickPrev');
  });
  $('.slick-floorplan.slick-next').click(function() {
    $('.floor-plan-carousel').slick('slickNext');
    $('.floor-plan-image-carousel').slick('slickNext');
  });

  // Gallery popup.
  if ($('.magnific-image').length) {
    $('.magnific-image').magnificPopup({
      type: 'image',
    });
  }

  // Image zoom.
  $('.tile')
    .on('mouseover', function() {
      $(this).children('.photo').css({
        'transform': 'scale(' + $(this).attr('data-scale') + ')'
      });
    })
    .on('mouseout', function() {
      $(this).children('.photo').css({
        'transform': 'scale(1)'
      });
    })
    .on('mousemove', function(e) {
      $(this).children('.photo').css({
        'transform-origin': ((e.pageX - $(this).offset().left) / $(this).width()) * 100 + '% ' + ((e.pageY - $(this).offset().top) / $(this).height()) * 100 + '%'
      });
    })
    .each(function() {
      $(this)
        .append('<div class="photo"></div>')
        .children('.photo').css({
          'background-image': 'url(' + $(this).attr('data-image') + ')'
        });
    })

  // AOS initialization.
  AOS.init();
});

/*
 * ACF Google Map Script
 */

(function($) {

  /*
   *  new_map
   *
   *  This function will render a Google Map onto the selected jQuery element
   *
   *  @type	function
   *  @date	8/11/2013
   *  @since	4.3.0
   *
   *  @param	$el (jQuery element)
   *  @return	n/a
   */

  function new_map($el) {
    // var
    var $markers = $el.find('.marker');

    // vars
    var args = {
      zoom: 16,
      disableDefaultUI: true,
      center: new google.maps.LatLng(0, 0),
      mapTypeId: google.maps.MapTypeId.ROADMAP,
    };

    // create map
    var map = new google.maps.Map($el[0], args);

    // add a markers reference
    map.markers = [];

    // add markers
    $markers.each(function() {
      add_marker($(this), map);
    });

    // center map
    center_map(map);

    // return
    return map;
  }

  /*
   *  add_marker
   *
   *  This function will add a marker to the selected Google Map
   *
   *  @type	function
   *  @date	8/11/2013
   *  @since	4.3.0
   *
   *  @param	$marker (jQuery element)
   *  @param	map (Google Map object)
   *  @return	n/a
   */

  function add_marker($marker, map) {
    // var
    var latlng = new google.maps.LatLng($marker.attr('data-lat'), $marker.attr('data-lng'));

    let pointer = "pointer.svg"
    if($($marker).hasClass('marker-green')) {
      pointer = "pointer-green.svg"
    }
    else if($($marker).hasClass('marker-light-navy')) {
      pointer = "pointer-light-navy.svg";
    }
    else if($($marker).hasClass('marker-navy')) {
      pointer = "pointer-navy.svg";
    }
    
    let url = `/app/themes/frogspark/img/${pointer}`;

    var icon = {
      url: url, // url
      scaledSize: new google.maps.Size(80, 80), // scaled size
    };

    // create marker
    var marker = new google.maps.Marker({
      position: latlng,
      map: map,
      icon: icon,
    });

    // add to array
    map.markers.push(marker);

    // if marker contains HTML, add it to an infoWindow
    if ($marker.html()) {
      // create info window
      var infowindow = new google.maps.InfoWindow({
        content: $marker.html()
      });

      // show info window when marker is clicked
      google.maps.event.addListener(marker, 'click', function() {
        infowindow.open(map, marker);
      });

      if (!$marker.attr('data-auto-show')) {
        var windowWidth = $(window).width();
        if(windowWidth >= 767) {
          infowindow.open(map, marker);
        }
      }

      // infowindow.open(map, marker);

    }
  }

  /*
   *  center_map
   *
   *  This function will center the map, showing all markers attached to this map
   *
   *  @type	function
   *  @date	8/11/2013
   *  @since	4.3.0
   *
   *  @param	map (Google Map object)
   *  @return	n/a
   */

  function center_map(map) {
    // vars
    var bounds = new google.maps.LatLngBounds();

    // loop through all markers and create bounds
    $.each(map.markers, function(i, marker) {
      var latlng = new google.maps.LatLng(marker.position.lat(), marker.position.lng());
      bounds.extend(latlng);
    });

    // only 1 marker?
    if (map.markers.length == 1) {
      // set center of map
      map.setCenter(bounds.getCenter());
      map.setZoom(14);
    } else {
      // fit to bounds
      map.fitBounds(bounds);
    }
  }

  /*
   *  document ready
   *
   *  This function will render each map when the document is ready (page has loaded)
   *
   *  @type	function
   *  @date	8/11/2013
   *  @since	5.0.0
   *
   *  @param	n/a
   *  @return	n/a
   */
  var map = null;
  $(document).ready(function() {
    $('.acf-map').each(function() {
      // Creates the map.
      map = new_map($(this));
    });
  });

  $(document).ready(function() {
    var windowWidth = $(window).width();
    if(windowWidth >= 767) {
      $('a.development-info-tab').on('shown.bs.tab', function (e) {
        var click = $(this);
        var anchor = click.attr('href');
        var pane = $(anchor);
        var map = $(pane).find('.acf-map-alt');
        if(!$(click).hasClass('map-created')){
          map = new_map(map);
        }
        $(click).addClass('map-created');
      })
    } else {
      $('.acf-map-alt').each(function() {
        // Creates the map.
        map = new_map($(this));
      });
    }
  });

})($);